import React from 'react';
import { Container, Section } from '@components/styledComponents/index';
import { FeatureImg, FeatureSection, FeatureText, Title, Text } from './styles';

const Article = props => {
  return (
    <>
      <FeatureSection bg={require('@images/' + props.image)}>
        <FeatureImg alt={`${props.title} image`} src={require('@images/' + props.image)} />
        <FeatureText>{props.featuredTitle}</FeatureText>
      </FeatureSection>
      <Container deskDir={'column'}>
        <Section padding={'0 1.4rem'}>
          <Title>{props.title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: props.content }} />
        </Section>
      </Container>
    </>
  );
};

export default Article;
