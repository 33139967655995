import styled from 'styled-components';

export const FeatureSection = styled.div`
  position: relative;
  overflow: hidden;
  &::before {
    content: " ";
    position: absolute;
    top: -2rem;
    bottom: -2rem;
    left: -2rem;
    right: -2rem;
    background: ${props => `url(${props.bg})`} no-repeat;
    background-size: cover;
    filter: blur(2rem);
    z-index: 1;
  }
  &::after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 3;
  }
`;

export const FeatureImg = styled.img`
  width: 100%;
  height: 14rem;
  object-fit: contain;
  position: relative;
  z-index: 2;
  @media (min-width: 1200px) {
    height: 43.7rem;
  }
`;

export const FeatureText = styled.div`
  align-items: center;
  display: flex;
  color: ${props => props.theme.white};
  font-family: 'Heuristica';
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 4.4rem;
  margin: 0;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 4;
  @media (min-width: 1200px) {
    font-size: 3.4rem;
    line-height: 4.4rem;
  }
`;

export const Title = styled.h1`
  font-family: 'Heuristica';
  font-weight: 600;
  font-size: 2.4rem;
  color: ${props => props.theme.quoteTxtColor};
  letter-spacing: 0;
  line-height: 2.8rem;
  margin: 0;
  @media (min-width: 1200px) {
    text-align: center;
    margin: 0 15rem 1.4rem;
  }
`;

export const Text = styled.p`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.5rem;
  color: ${props => props.theme.articleTxt};
  letter-spacing: 0;
  text-align: ${props => props.align || 'left'};
  line-height: 2.4rem;
  a {
    color: ${props => props.theme.articleTxt};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  @media (min-width: 1200px) {
    margin: ${props => props.deskMargin || '0 15rem'};
    text-align: left;
  }
`;
