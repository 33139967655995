import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import Article from '@components/pages/article';

const BlogPage = props => {
  const [blog, setBlog] = useState(null);
  const {
    site: {
      siteMetadata: { blogs },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            blogs {
              image
              title
              featuredTitle
              content
              link
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!blogs) {
      return;
    }
    
    const _blog = blogs.find(
      item => process.env.BaseURL + item.link === props.location.pathname
      );
    setBlog(_blog);
  }, [props.location.pathname, blogs]);

  return (
    <Layout {...props}>
      <SEO title={blog?.title ? `${blog.title} | Blogs` : 'Blogs'} />
      {blog && <Article
        image={blog.image}
        title={blog.title}
        featuredTitle={blog.featuredTitle}
        content={blog.content}
      />}
    </Layout>
  );
};

export default BlogPage;
